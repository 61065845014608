var render, staticRenderFns
import script from "./StaticLabelInjector.vue?vue&type=script&lang=js&"
export * from "./StaticLabelInjector.vue?vue&type=script&lang=js&"
import style0 from "./StaticLabelInjector.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_0528834a/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1967a8e3')) {
      api.createRecord('1967a8e3', component.options)
    } else {
      api.reload('1967a8e3', component.options)
    }
    
  }
}
component.options.__file = "app/javascript/admin/static-labels/StaticLabelInjector.vue"
export default component.exports